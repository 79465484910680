import React, { useState, useEffect } from 'react';
import LicenseInputPage from './components/LicenseInputPage';
import HomePage from './components/HomePage';

const App = () => {
  const [hasLicense, setHasLicense] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkLicenseStatus = async () => {
      // Simulating an API call to check license status
      const storedLicense = localStorage.getItem('license');
      if (storedLicense) {
        setHasLicense(true);
      }
      setIsLoading(false);
    };

    checkLicenseStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {hasLicense ? <HomePage /> : <LicenseInputPage onLicenseSubmit={() => setHasLicense(true)} />}
    </div>
  );
};

export default App;