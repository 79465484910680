import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaHockeyPuck } from 'react-icons/fa';
import styled from 'styled-components';

const Container = styled.div`
  background-image: url(https://s1.vpsfa.store/bg.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const Menu = styled(motion.div)`
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  max-width: 600px;
`;

const MenuItem = styled.div`
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s, color 0.3s;

  &:hover {
    transform: scale(1.1);
    color: #f39c12;
  }

  div {
    margin-top: 5px;
    font-size: 14px;
  }
`;

const HomePage = () => {
  const redirectToSite = (url) => {
    window.location.href = url;
  };

  return (
    <Container>
      <Menu
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <MenuItem onClick={() => redirectToSite('http://mux87.online/users/login')}>
          <FaHockeyPuck size={35} />
          <div>ورود به سایت دوبیکس بت</div>
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default HomePage;